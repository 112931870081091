import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import './normalize.css';
import './App.css';

import LandingPage from './components/LandingPage'
import withAuthorization from './components/Auth'
import LoginPage from './components/LoginPage'
import LogoutPage from './components/LogoutPage'
import UserProfilePage from './components/UserProfilePage'
import NavBar from './components/NavBar';
import SubmitPage from './components/SubmitPage';
import ReviewPage from './components/ReviewPage';
import NotificationsPage from './components/NotificationsPage';

const App = () => (
  <Router>
    <NavBar />
    <Route exact path="/" component={LandingPage} />
    <Route path="/login" component={LoginPage} />
    <Route path="/logout" component={LogoutPage} />
    <Route path="/u/:username" component={UserProfilePage} />
    <Route path="/r/:slug" component={ReviewPage} />
    <Route path="/submit" component={withAuthorization(u => !!u, 'Please sign in to submit code for review')(SubmitPage)} />
    <Route path="/notifications" component={withAuthorization(u => !!u, 'Please sign in to view your notifications')(NotificationsPage)} />
  </Router>
)

export default App;
