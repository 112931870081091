import React, { useEffect, useState } from 'react'
import { HasFirebase, withFirebase, useUserProfile } from './Firebase'
import { useParams, Link } from 'react-router-dom'
import { Review } from '../models'
import { File } from './SubmitPage'
import AnnotatedFile from './AnnotatedFile'
import { Comment } from '../models'
import styles from './ReviewPage.module.css'
import { firestore } from 'firebase/app'
import { timeSince, trimProtocol } from '../formatUtils'

const ReviewPage: React.FC<HasFirebase> = ({ firebase }) => {
    const { slug } = useParams()
    const [ review, setReview ] = useState<Review | null>(null)
    const [ files, setFiles ] = useState<File[]>([])
    const [ comments, setComments ] = useState<Comment[]>([])
    const userProfile = useUserProfile()

    useEffect(() => {
        if (!slug) return

        firebase.firestore
            .collection('reviews')
            .where('slug', '==', slug)
            .get()
            .then(snapshot => {
                const docs = snapshot.docs
                if (docs.length < 1) return
                const doc = docs[0]
                setReview({
                    ...doc.data(),
                    id: doc.id,
                } as Review)
                return doc.id
            })
            .then(reviewId => {
                if (!reviewId) return

                firebase.firestore
                    .collection('files')
                    .where('review', '==', reviewId)
                    .get()
                    .then(snapshot => {
                        if (!snapshot) return
                        setFiles(snapshot.docs.map(doc => doc.data() as File))
                    })

                firebase.firestore
                    .collection('comments')
                    .where('review', '==', reviewId)
                    .orderBy('createdAt', 'asc')
                    .onSnapshot(snapshot => {
                        if (!snapshot) return

                        const cs = 
                            snapshot.docs.map(doc => ({
                                ...doc.data(),
                                id: doc.id,
                                replies: [] as Comment[],
                            } as Comment))
                        
                        const groupedComments = cs.reduce((grouped, c) => {
                            if (c.repliesTo) {
                                grouped[c.repliesTo].replies.push(c)
                            } else {
                                grouped[c.id] = c
                            }

                            return grouped
                        }, {} as Record<string, Comment>)

                        setComments(Object.values(groupedComments))
                    })
            })

    }, [ slug, firebase.firestore ])

    return (!review 
    ? <></>
    :<>
        <section className={styles.header}>
            <div className={styles.reviewCard}>
                <div className={styles.header}>
                    <img src={review.creatorAvatar} alt={review.creatorName + ' avatar'} className={styles.avatar}/>
                    <Link className={styles.userName} to={'/u/' + review.creatorName}>{review.creatorName}</Link>
                    <span className={styles.createdAt}>{timeSince(review.createdAt.toDate())} ago</span>
                    <span className={styles.spacer}></span>
                </div>
                <h2>{review.title}</h2>
                <span className={styles.language}>{review.language}</span>
                {review.repositoryUrl && <a href={review.repositoryUrl} className={styles.repoUrl}>{trimProtocol(review.repositoryUrl)}</a>}
                <p className={styles.description}>
                    {review.description}
                </p>
            </div>
        </section>
        {files.map(file => 
        <div key={file.path} className={styles.fileSection}>
            <h4 className={styles.fileName}>{file.path}</h4>
            <AnnotatedFile file={file} 
            comments={comments.filter(c => c.file === file.path)} 
            reviewSlug={slug || ''}
            isLoggedOut={!userProfile}
            addComment={(startLine: number, text: string, repliesTo: string | null) => {
                const batch = firebase.firestore.batch()
                const commentDoc = firebase.firestore.collection('comments').doc()
                batch.set(commentDoc, {
                        review: review.id,
                        filePath: file.path,
                        startLine,
                        text,
                        repliesTo,
                        respondentIds: [],
                        authorId: userProfile?.uid,
                        authorName: userProfile?.username,
                        authorAvatarURL: userProfile?.photoURL,
                        createdAt: firestore.FieldValue.serverTimestamp(),
                        file: file.path,
                })

                const reviewDoc = firebase.firestore.collection('reviews').doc(review.id)
                const uid = firebase.currentUser()?.uid || ''
                batch.update(reviewDoc, {
                    commentCount: firestore.FieldValue.increment(1),
                    commenterIds: firestore.FieldValue.arrayUnion(uid),
                    commenterAvatars: {
                        [uid]: userProfile?.photoURL,
                    },
                    commenterNames: {
                        [uid]: userProfile?.username,
                    },
                })

                const userProfileDoc = firebase.firestore.collection('users').doc(uid)
                batch.update(userProfileDoc, {
                    commentCount: firestore.FieldValue.increment(1),
                })

                if (repliesTo) {
                    const repliesToDoc = firebase.firestore.collection('comments').doc(repliesTo)
                    batch.update(repliesToDoc, {
                        respondentIds: firestore.FieldValue.arrayUnion(uid),
                    })
                }

                return batch.commit()
            }
            }/>
        </div>)}
    </>)
}

export default withFirebase(ReviewPage)