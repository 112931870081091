import React from 'react'
import styles from './ReviewList.module.css'
import { timeSince, trimProtocol } from '../formatUtils'
import { Review } from '../models'
import { Link } from 'react-router-dom'

type Props = {
    reviews: Review[]
}

const ReviewList: React.FC<Props> = ({ reviews }) => {
    return (
            <ul className={styles.reviewList}>
                {reviews.map(r => (
                    <li key={r.slug}>
                            <div className={styles.reviewCard}>
                                <Link to={`/r/${r.slug}`} className={styles.reviewCardLink}></Link>
                                <div className={styles.reviewCardContent}>
                                    <div className={styles.header}>
                                        <img src={r.creatorAvatar} alt={r.creatorName + ' avatar'} className={styles.avatar}/>
                                        <Link className={styles.userName} to={'/u/' + r.creatorName}>{r.creatorName}</Link>
                                        <span className={styles.createdAt}>{timeSince(r.createdAt.toDate())} ago</span>
                                        <span className={styles.spacer}></span>
                                            <span className={styles.comments}>{r.commentCount} {r.commentCount === 1 ? 'comment' : 'comments'}</span>
                                    </div>
                                    <h3>{r.title}</h3>
                                    <span className={styles.language}>{r.language}</span>
                                    {r.repositoryUrl && <a href={r.repositoryUrl} className={styles.repoUrl}>{trimProtocol(r.repositoryUrl)}</a>}
                                    <p className={styles.description}>
                                        {r.description}
                                    </p>
                                </div>
                            </div>
                    </li>
                ))}
            </ul>
    )
}

export default ReviewList