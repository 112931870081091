import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react'
import hljs from 'highlight.js'
import 'highlight.js/styles/dracula.css'
import { File } from './SubmitPage'
import styles from './AnnotatedFile.module.css'
import { Comment } from '../models'
import { shortTimeSince } from '../formatUtils'
import Markdown from 'react-markdown'
import { useLocation, useHistory, Link } from 'react-router-dom'

export type Props = {
    file: File,
    comments: Comment[],
    addComment: (startLine: number, text: string, repliesTo: string | null) => Promise<void>
    className?: string
    isLoggedOut?: boolean
    reviewSlug: string
}

type Annotation = CommentAnnotation | CommentInput

type CommentAnnotation = Comment & {
    type: "comment"
}

type CommentInput = {
    type: "comment-input"
    startLine: number,
}

const trimHash = (hash: string) => 
    hash.startsWith('#') ? hash.slice(1) : hash

const AnnotatedFile: React.FC<Props> = ({ file, comments, addComment, className, isLoggedOut, reviewSlug }) => {
    const location = useLocation()
    const history = useHistory()

    const [ selectedLines, setSelectedLines ] = useState<Set<number>>(new Set([]))
    const [ annotations, setAnnotations ] = useState<Annotation[]>(
        comments
            .sort((a, b) => a.startLine - b.startLine)
            .map(c => ({
                ...c,
                type: "comment",
            })))
    const [ commentInput, setCommentInput ] = useState("")
    const [ isAddingComment, setIsAddingComment ] = useState(false)
    const [ , setRedraw ] = useState(0)

    const [ offsets, setOffsets ] = useState<number[]>(annotations.map(() => 0))
    const [ heights, setHeights ] = useState<number[]>(annotations.map(() => 0))
    const [ positions, setPositions ] = useState<number[]>(annotations.map(() => 0))


    const [ selectedCommentId, setSelectedCommentId ] = useState<string | null>(trimHash(location.hash) || null)
    const divsRef = useRef<(HTMLDivElement | null)[]>([])


    useLayoutEffect(() => {
        divsRef.current = divsRef.current.slice(0, annotations.length)
        setHeights(divsRef.current.map(elem => (elem?.clientHeight || 0) + 4))
    }, [ annotations ])

    useEffect(() => {
        setCommentInput("")
        const path = location.pathname + (selectedCommentId ? ('#' + selectedCommentId) : '')
        history.replace(path)
    }, [ selectedCommentId, history, location.pathname ])

    useEffect(() => {
        const interval = window.setInterval((() => {
            setRedraw(r => r+1)
        }), 10000)

        return () => {
            window.clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (selectedLines.size > 0) {
            const startLine = Math.min(...Array.from(selectedLines.values()))
            let i = 0
            while (comments[i] && comments[i].startLine < startLine) i++
            const a: Annotation[] = 
            comments
                .sort((a, b) => a.startLine - b.startLine)
                .map(c => ({
                    ...c,
                    type: "comment",
                }))
            setAnnotations([...a.slice(0, i), {
                type: "comment-input",
                startLine,
            }, ...a.slice(i)])
        } else {
            setCommentInput("")
            setAnnotations(
                comments
                    .sort((a, b) => a.startLine - b.startLine)
                    .map(c => ({
                        ...c,
                        type: "comment",
                    })))
        }
    }, [ comments, selectedLines ])


    useEffect(() => {
        let currentOffset = 0
        const offs = annotations.map(c => c.startLine)
        const pos = []
        for (let i = 0; i < annotations.length; i++) {
            const targetOffset = annotations[i].startLine * 17
            let offset = 0
            if (currentOffset < targetOffset) {
                offset = targetOffset - currentOffset
            }
            currentOffset += offset 
            pos.push(currentOffset)
            currentOffset += heights[i]
            offs[i] = offset
        }
        setPositions(pos)
        setOffsets(offs)
    }, [ annotations, heights ])


    const highlightRef = useCallback((node: HTMLElement | null) => {
        node && hljs.highlightBlock(node)
    }, [])

    const highlightMarkdown = useCallback((node: HTMLElement | null) => {
        if (!node) return

        const blocks = node.getElementsByTagName("code")
        for (let i = 0; i < blocks.length; i++) {
            hljs.highlightBlock(blocks[i])
        }
    }, [])

    const selectedComments = selectedCommentId && comments.filter(c => c.id === selectedCommentId)
    const selectedComment = selectedComments && selectedComments.length > 0 && selectedComments[0]

    const fileParts = file.path.split('.')
    const fileExt = fileParts[fileParts.length-1]


    return <div className={className + ' ' + styles.wrapper}>
        <pre className={styles.file}><code ref={highlightRef} style={{borderRadius: "8px"}} className={fileExt}>{file.content}</code></pre>
        <div style={{ position: "absolute", padding: "8px 0", top: "0" }}>
            {file.content.trimEnd().split('\n').map((l, idx) =>
                <pre key={idx} onClick={() => {
                    if (selectedLines.has(idx)) {
                        setSelectedLines(s => {
                            s.delete(idx)
                            return new Set(s)
                        })
                    } else {
                        setSelectedLines(s => new Set(s.add(idx)))
                    }

                }} className={`${styles.line} ${selectedLines.has(idx) ? ' ' + styles.selected : ''}`}>{l + '\n'}</pre>)}
        </div>
        <div className={styles.commentPane}>
            {selectedComment
            ? <div
               className={styles.comment}
               style={{
                   marginTop: `${selectedComment.startLine * 17}px`,
               }}>
                    <svg 
                     style={{
                        marginLeft: `${-1.0 * (24 - 10)}px`,
                        position: "absolute",
                        marginTop: `${- 2 + 8}px`,
                        pointerEvents: "none",
                        zIndex: 101,
                     }}
                     height={16 - 8 + 10} 
                     width={1.0 * (24 - 8)}>

                     <path 
                      d={`M 2 2 Q ${1.0 * (24 - 8) / 2} ${16 - 8} ${1.0 * (24 - 8)} ${16 - 8}`}
                      strokeLinecap="round"
                      stroke="#a0e" 
                      strokeWidth="4" 
                      fill="none" />
                    </svg>
                    <div className={styles.overlay} onClick={() => setSelectedCommentId(null)}></div>
                    <div className={styles.commentContent}>
                        <div className={styles.commentText + ' ' + styles.selectedCommentText}>
                            <div className={styles.commentRow} >
                                <Link to={`/u/${selectedComment.authorAvatarURL}`}> 
                                    <img src={selectedComment.authorAvatarURL} alt={`${selectedComment.authorName} avatar`} className={styles.avatar}/>
                                </Link>
                                <div className={styles.commentMain}>
                                    <div className={styles.commentTextHeader}>
                                        <Link to={`/u/${selectedComment.authorName}`} className={styles.userName}>{selectedComment.authorName}</Link>
                                        <span className={styles.createdAt}>{shortTimeSince(selectedComment.createdAt?.toDate() || Date.now())} ago</span>
                                    </div>

                                    <div ref={highlightMarkdown}><Markdown className={styles.markdown} source={selectedComment.text} /></div>
                                </div>
                            </div>
                                    {selectedComment.replies.map(r => 
                                    <React.Fragment key={r.id}>
                                    <div className={styles.commentRow} >
                                        <Link to={`/u/${r.authorName}`}> 
                                            <img src={r.authorAvatarURL} alt={`${r.authorName} avatar`} className={styles.avatar}/>
                                        </Link>
                                        <div className={styles.commentMain}>
                                            <div className={styles.commentTextHeader}>
                                                <Link to={`/u/${r.authorName}`} className={styles.userName}>{r.authorName}</Link>
                                                <span className={styles.createdAt}>{shortTimeSince(r.createdAt?.toDate() || Date.now())} ago</span>
                                            </div>

                                            <div ref={highlightMarkdown}><Markdown className={styles.markdown} source={r.text} /></div>
                                        </div>
                                    </div>
                                    </React.Fragment>)}


                                    <div className={styles.commentForm} >
                                    {!isLoggedOut
                                    ?   <form onSubmit={async (ev) => {
                                            ev.preventDefault()
                                            if (!commentInput || commentInput === '') return

                                            setIsAddingComment(true)
                                            await addComment(selectedComment.startLine, commentInput, selectedComment.id)
                                            setCommentInput('')
                                            setIsAddingComment(false)
                                        }}>
                                            <textarea
                                             value={commentInput}
                                             rows={5}
                                             placeholder="Reply..."
                                             onChange={ev => setCommentInput(ev.target.value)}></textarea>
                                            <button type="submit" className={styles.commentButton}>Comment</button>
                                        </form>
                                    : <p style={{marginTop: "16px"}}><Link to={`/login?redirect=/r/${reviewSlug}`}>Sign in</Link> to comment</p>
                                    }
                                    </div>
                                <button className={styles.commentCloseButton} onClick={() => setSelectedCommentId(null)}>Close</button>
                        </div>
                    </div>
                </div>

            : annotations.map((c, idx) => <React.Fragment key={
                c.type === "comment"
                    ? c.createdAt?.toMillis() || c.text
                    : "input"
            }>
                <div
                    ref={el => divsRef.current[idx] = el}
                    className={styles.comment}
                    style={{
                        marginTop: `${offsets[idx] + 4}px`,
                    }}>
                        <svg 
                         style={{
                            marginLeft: `${-1.0 * (.2 * (positions[idx] - c.startLine * 17) + 24 - 10)}px`,
                            position: "absolute",
                            marginTop: `${c.startLine * 17 - positions[idx] - 2 + 8}px`,
                            pointerEvents: "none",
                            zIndex: 101,
                         }}
                         height={positions[idx] - c.startLine * 17 + 16 - 8 + 10} 
                         width={1.0 * (positions[idx] - c.startLine * 17 + 24 - 8)}>

                         <path 
                          d={`M 2 2 Q ${1.0 * (.2 * (positions[idx] - c.startLine * 17) + 24 - 8) / 2} ${positions[idx] - c.startLine * 17 + 16 - 8} ${1.0 * (.2 * (positions[idx] - c.startLine * 17) + 24 - 8)} ${positions[idx] - c.startLine * 17 + 16 - 8}`}
                          strokeLinecap="round"
                          stroke="#a0e" 
                          strokeWidth="4" 
                          fill="none" />
                        </svg>
                    <div className={styles.commentContent}>
                        {c.type === "comment"
                            ? <div className={styles.commentText} onClick={() => setSelectedCommentId(c.id)}>
                                <div className={styles.commentRow} style={c.replies.length > 0 ? {} : {marginBottom: "2px"}}>
                                  <Link to={`/u/${c.authorName}`}> 
                                      <img src={c.authorAvatarURL} alt={`${c.authorName} avatar`} className={styles.avatar}/>
                                  </Link>
                                  <div className={styles.commentMain}>
                                      <div className={styles.commentTextHeader}>
                                          <Link to={`/u/${c.authorName}`} className={styles.userName}>{c.authorName}</Link>
                                          <span className={styles.createdAt}>{shortTimeSince(c.createdAt?.toDate() || Date.now())} ago</span>
                                      </div>
                                      <div ref={highlightMarkdown}><Markdown className={styles.markdown} source={c.text} /></div>
                                      <button className={styles.commentReplyButton}>Reply</button>
                                  </div>
                                </div>
                                {c.replies.length > 1 && <div className={styles.commentRow + ' ' + styles.replyFold}>
                                    {c.replies.length - 1} more repl{c.replies.length > 2 ? 'ies': 'y'}
                                </div>}
                                {c.replies.length > 0 && <div className={styles.commentRow} style={{marginBottom: "2px"}}>
                                  <Link to={`/u/${c.replies[c.replies.length-1].authorName}`}> 
                                      <img src={c.replies[c.replies.length-1].authorAvatarURL} alt={`${c.replies[c.replies.length-1].authorName} avatar`} className={styles.avatar}/>
                                  </Link>
                                  <div className={styles.commentMain}>
                                      <div className={styles.commentTextHeader}>
                                          <Link to={`/u/${c.replies[c.replies.length-1].authorName}`} className={styles.userName}>{c.replies[c.replies.length-1].authorName}</Link>
                                          <span className={styles.createdAt}>{shortTimeSince(c.replies[c.replies.length-1].createdAt?.toDate() || Date.now())} ago</span>
                                      </div>
                                      <div ref={highlightMarkdown}><Markdown className={styles.markdown} source={c.replies[c.replies.length-1].text} /></div>
                                      <button className={styles.commentReplyButton}>Reply</button>
                                  </div>
                                </div>}
                              </div>
                            : <div className={styles.commentInput}>
                                {!isLoggedOut 
                                ? <form onSubmit={async (ev) => {
                                        ev.preventDefault()
                                        if (!commentInput || commentInput === '') return

                                        setIsAddingComment(true)
                                        await addComment(c.startLine, commentInput, null)
                                        setSelectedLines(new Set([]))
                                        setCommentInput('')
                                        setIsAddingComment(false)
                                    }}>
                                        <textarea 
                                         rows={5} 
                                         value={commentInput}
                                         placeholder="Write a comment" 
                                         onChange={(ev) => setCommentInput(ev.target.value)}>
                                        </textarea>
                                        <button disabled={isAddingComment} className={styles.commentButton}>{isAddingComment ? "..." : "Comment"}</button>
                                    </form>
                                : <p style={{margin: 0}}><Link to={"/login?redirect=/r/" + reviewSlug}>Sign in</Link> to comment</p>
                                }
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>)}
        </div>
    </div>
}

export default AnnotatedFile