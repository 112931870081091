import React, { useState, useEffect } from 'react'
import { HasFirebase, withFirebase } from './Firebase'
import { Review } from '../models'
import ReviewList from './ReviewList'
import { Link } from 'react-router-dom'
import styles from './ReviewListPage.module.css'


const ReviewListPage: React.FC<HasFirebase> = ({ firebase }) => {
    const [ reviews, setReviews ] = useState<Review[]>([])
    const [ isLoadingMore, setIsLoadingMore ] = useState(false)
    const user = firebase.currentUser()

    const loadMore = () => {
        setIsLoadingMore(true)
        firebase.firestore
            .collection('reviews')
            .orderBy('createdAt', 'desc')
            .startAfter(reviews[reviews.length - 1].createdAt)
            .limit(12)
            .get()
            .then(res => 
                setReviews(rs => rs.concat(res.docs.map(d => ({
                    ...d.data(),
                    id: d.id,
                 }) as Review))))
            .then(() => setIsLoadingMore(false))
    }

    useEffect(() => {
        firebase.firestore
            .collection('reviews')
            .orderBy('createdAt', 'desc')
            .limit(12)
            .get()
            .then(res => 
                setReviews(res.docs.map(d => ({
                    ...d.data(),
                    id: d.id,
                 }) as Review)))
    }, [ firebase.firestore ])

    return (<>
        {!user && reviews.length > 0 && 
            <div className={styles.hero}>
                <section className={styles.heroContent}>

                <div className={styles.heroText}>
                    <em>CodeReviewExchange</em> is a community where developers can review each others code for mutual learning.
                </div>
                <Link to="/login" className={styles.heroButton}>Join</Link>
                </section>
            </div>
        }
        <section>
            <h1>Latest Reviews</h1>
            <ReviewList reviews={reviews} />
            {reviews.length > 0 &&
                <button className={styles.loadMoreButton} onClick={loadMore} disabled={isLoadingMore}>
                    Load more
                </button>}
        </section>
    </>)
}

export default withFirebase(ReviewListPage)