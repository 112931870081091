import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import styles from './CodeSourceUpload.module.css'
import { File } from './SubmitPage'

type Props = {
    setFiles: (files: File[]) => void
}

const CodeSourceUpload: React.FC<Props> = props => {
    const [ files, setFiles ] = useState<File[]>([])

    useEffect(() => {
        props.setFiles(files)
    }, [ files, props ])

    return (
        <div>
            <Dropzone onDrop={(files) => {
                files.forEach(file => {
                    const reader = new FileReader()
                    reader.onload = (ev) => {
                        const result = ev.target?.result
                        if (!result) return
                        setFiles(fs => [...fs, {
                            path: file.name,
                            content: result.toString(),
                        }])
                    }
                    reader.readAsText(file)
                })
            }}>
            {({getRootProps, getInputProps}) => (
                    <div className={styles.dropzone} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drop files here, or click to select files</p>
                    </div>
                )}
            </Dropzone>

            <ul className={styles.fileList}>
                {files.map((f, idx) => 
                <li key={f.path + idx.toString()}>
                    {f.path}
                    <button type="button" onClick={ev => {
                        ev.preventDefault()
                        setFiles(fs => [...fs.slice(0, idx), ...fs.slice(idx + 1)])
                    }}>x</button>
                </li>)}
            </ul>
        </div>)

}

export default CodeSourceUpload