import React, { useState } from 'react'
import styles from './CodeSourceGithub.module.css'
import { File } from './SubmitPage'

export type PartialFile = {
    path: string
    content: null | string
}

type APITreeResponse = {
    sha: string
    url: string
    truncated: boolean
    tree: {
        path: string
        type: 'blob' | 'tree'
        url: string
    }[]
}

type Props = {
    setFiles: (files: File[]) => void
    setRepositoryUrl: (url: string) => void
}

const CodeSourceGithub: React.FC<Props> = (props) => {
    const [ timer, setTimer ] = useState<number | null>(null)
    const [ files, setFiles ] = useState<PartialFile[]>([])
    const [ repo, setRepo ] = useState<string | null>(null)

    const isLoading = timer !== null && files.length < 1

    const loadFiles = (repo: string) => {
        setRepo(repo)
        props.setRepositoryUrl('https://github.com/' + repo)
        fetch(`https://api.github.com/repos/${repo}/git/trees/master?recursive=True`)
            .then(res => res.json())
            .then(res => res as APITreeResponse)
            .then(res => setFiles(
                res.tree
                    .filter(f => f.type === 'blob')
                    .map(f => ({ ...f, content: null}))))
            .catch(err => console.log(err))
    }

    const onInputChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        (timer !== null) && clearTimeout(timer)

        let repo = ev.target.value
        repo.startsWith('https://github.com/') && (repo = repo.substring(19))
        repo.startsWith('http://github.com/') && (repo = repo.substring(18))
        repo.startsWith('github.com/') && (repo = repo.substring(11))

        setTimer(window.setTimeout(() => loadFiles(repo), 2000))
    }


    return (
        <div>
        <label>
            <h5>Repository</h5>
            <input type="text" onChange={onInputChanged} placeholder="e.g. github.com/julvo/tinystatic"/>
        </label>
        {isLoading 
        ? <div>loading...</div>
        : <ul className={styles.fileList}>
            {files.map((f, idx) => (
                <li key={f.path}>
                    <input type="checkbox" checked={f.content !== null} onChange={() => {
                        if (f.content == null) {
                            return fetch(`https://raw.githubusercontent.com/${repo}/master/${f.path}`)
                                .then(res => res.text())
                                .then(res => setFiles(fs => {
                                    fs[idx].content = res
                                    props.setFiles(fs
                                        .filter(f => (f.content !== null))
                                        .map(f=> ({
                                            path: f.path,
                                            content: f.content || '',
                                        })))
                                    return [...fs]
                                }))
                                .catch(console.log)
                        }

                        setFiles(fs => {
                            fs[idx].content = null
                            props.setFiles(fs
                                .filter(f => (f.content !== null))
                                .map(f=> ({
                                    path: f.path,
                                    content: f.content || '',
                                })))
                            return [...fs]
                        })
                    }}/>
                    {f.path}
                </li>
            ))}
        </ul>}
        </div>
    )
}

export default CodeSourceGithub