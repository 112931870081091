import React from 'react'
import { useUserProfile } from './Firebase'
import { Link, useLocation } from 'react-router-dom'
import styles from './NavBar.module.css'
import logo from './codereviewexchange_simple_min.svg'
import mobileLogo from './codereviewexchange_3lines_simple_min.svg'

const NavBar: React.FC = () => {
    const userProfile = useUserProfile()
    const location = useLocation()

    const isLogin = location.pathname.startsWith('/login')

    return (
    <nav className={styles.navbar}>
        <div>
            <ul>
                <li>
                    <Link to="/">
                        <img className={styles.logo + ' hiddenMobile'} src={logo} alt="codereviewexchange" />
                        <img className={styles.logo + ' hiddenDesktop'} src={mobileLogo} alt="codereviewexchange" />
                    </Link>
                </li>
            </ul>
        </div>

        <div>
            {!isLogin && <ul>
                    {!userProfile && <li>
                        <Link className={styles.navLink + ' ' + styles.secondary} to={{
                            pathname: "/login",
                            state: {
                                message: "Sign in"
                            }
                        }}>
                            Sign in
                        </Link>
                    </li>}
                <li><Link className={styles.navLink} to="/submit">Submit code<span className="hiddenMobile"> for review</span></Link></li>
                {userProfile && <li>
                    <div className={styles.dropdown}>
                        <button><img className={styles.avatar} src={userProfile.photoURL || ""} alt={userProfile.username || ""}/></button>
                        <div className={styles.dropdownContent}>
                            <Link to="/notifications">Notifications</Link>
                            <Link to={`/u/${userProfile?.username}`}>Profile</Link>
                            <a href="mailto:contact@codereviewexchange.com">Send Feedback</a>
                            <hr />
                            <Link to="/logout">Sign out</Link>
                        </div>
                    </div>
                </li>}
            </ul>}
        </div>
    </nav>)
}

export default NavBar