import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './SubmitPage.module.css'
import { HasFirebase, withFirebase, useUserProfile } from './Firebase'
import { firestore } from 'firebase/app'

import CodeSourceGithub from './CodeSourceGithub'
import CodeSourceUpload from './CodeSourceUpload'

type CodeSource = 'Upload files' | 'Import from Github'

export type File = {
    path: string
    content: string
}

const SubmitPage: React.FC<HasFirebase> = ({ firebase }) => {
    const [ codeSource, setCodeSource ] = useState<CodeSource>('Import from Github')
    const [ files, setFiles ] = useState<File[]>([])
    const [ repositoryUrl, setRepositoryUrl ] = useState<string | null>(null)
    const [ title, setTitle ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ language, setLanguage ] = useState('')
    const userProfile = useUserProfile()
    const codeSources: CodeSource[] = ['Import from Github', 'Upload files']
    const history = useHistory()

    useEffect(() => {
        setRepositoryUrl(null)
        setFiles([])
    }, [ codeSource ])

    const submit = () => {
        const batch = firebase.firestore.batch()
        const reviewDoc = firebase.firestore.collection('reviews').doc()
        batch.set(reviewDoc, {
            creatorId: firebase.currentUser()?.uid,
            creatorName: userProfile?.username || '',
            creatorAvatar: userProfile?.photoURL,
            title,
            description,
            slug: (userProfile?.username + '-' + title).toLowerCase().replace(/\W/g, '-') + '-' + reviewDoc.id.substring(13),
            createdAt: firestore.FieldValue.serverTimestamp(),
            commentCount: 0,
            commenterIds: [],
            commenterAvatars: {},
            commenterNames: {},
            language,
            repositoryUrl,
        })

        files.forEach(f => {
            const fileDoc = firebase.firestore.collection('files').doc()
            batch.set(fileDoc, {
                owner: firebase.currentUser()?.uid,
                review: reviewDoc.id,
                ...f,
            })
        })
        batch.commit()
            .then(() => history.push('/'))
    }

    return <section className={styles.submitPage}>
        <h1>
            Submit code for review
        </h1>

        <form onSubmit={(ev) => {
            ev.preventDefault()
            submit()
        }}>
            <div>
                <label>
                    <h5>Title</h5>
                    <input type="text" onChange={(ev) => setTitle(ev.target.value)}/>
                </label>
            </div>

            <div>
                <label>
                    <h5>Description</h5>
                    <textarea 
                     rows={5} 
                     placeholder="Add some context for the reviewer, e.g. the task or problem that your code is solving." 
                     onChange={(ev) => setDescription(ev.target.value)}>
                     </textarea>
                </label>
            </div>

            <div>
                <label>
                    <h5>Language</h5>
                    <input type="text" 
                    onChange={(ev) => setLanguage(ev.target.value)}
                    placeholder="e.g. Python"
                    />
                </label>
            </div>

            <div>
                <h5 style={{
                    marginTop: "4em", 
                    marginBottom: "-3em"
                }}>Code</h5>
            </div>

            <ul className={styles.codeSourceTabs}>
                <div></div>
                {codeSources.map(src => (
                <button key={src} onClick={(ev) => {
                    ev.preventDefault()
                    setCodeSource(src)
                 }} className={src === codeSource ? styles.active : ''}>
                    <li>{src}</li>
                </button>
                ))}
                <div></div>
            </ul>

            {codeSource === 'Upload files'  ? (
                <CodeSourceUpload setFiles={setFiles}/>)
            : codeSource === "Import from Github" ? (
                <div>
                    <CodeSourceGithub setFiles={setFiles} setRepositoryUrl={setRepositoryUrl}/>
                </div>) 
            : (<></>)}

            <div>
                <button type="submit" disabled={files.length < 1 || !title || !language || !description}>Submit ></button>
            </div>

        </form>
    </section>
}

export default withFirebase(SubmitPage)