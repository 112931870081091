import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withFirebase, Firebase } from './Firebase';


type Props = {
    firebase: Firebase
}

const withAuthorization = 
    (condition: (user: firebase.User | null) => boolean, msg?: string) => 
    <P extends {}>(Component: React.FC<P>) => {
        const WithAuthorization: React.FC<P & Props> = ({firebase}, props) => {
            const [ user, setUser ] = useState(firebase.currentUser())
            const history = useHistory()
            const location = useLocation()
            useEffect(() => {
                const listener = firebase.auth.onAuthStateChanged(user => {
                        setUser(user) 
                        const redirectUrl = '/login' + (
                            location.pathname 
                                ? '?redirect=' + location.pathname
                                : '')
                        !condition(user) && history.push(redirectUrl, {
                            message: msg || 'Please log in to continue',
                        })
                    })

                return listener
            }, [ firebase, history, location.pathname ])

            return condition(user) 
                ? <Component {...props} />
                : <div></div>
        }

        return withFirebase(WithAuthorization)
    }

export default withAuthorization;