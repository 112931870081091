import React from 'react'
import styles from './LoginPage.module.css'
import { HasFirebase, withFirebase, useUser } from './Firebase'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

const LoginPage: React.FC<HasFirebase> = ({ firebase }) => {
    const history = useHistory()
    const location = useLocation()
    const user = useUser()

    const msg = (location.state as any)?.message

    return (
        user 
        ? <Redirect to="/" />
        : <section>
            <div className={styles.loginCard}>
                {msg && <h2>{msg}</h2>}
                <p>If you're signing in for the first time, we will create a new account for you.</p>
                <button onClick={() => {
                    const params = new URLSearchParams(location.search)
                    const redirectUrl = params.get('redirect') || '/'
                    firebase.signInWithGithub()
                        .then(() => 
                            history.replace(redirectUrl))
                }}>
                    <FontAwesomeIcon icon={faGithub} size="lg"/>
                    Sign in with GitHub
                </button>
            </div>
        </section>
    )
}

export default withFirebase(LoginPage)