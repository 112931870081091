import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { HasFirebase, withFirebase } from './Firebase'

const LogoutPage: React.FC<HasFirebase> = ({ firebase }) => {
    useEffect(() => {
        firebase.auth.signOut()
    })

    return (<Redirect to="/" />)
}

export default withFirebase(LogoutPage)