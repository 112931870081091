import React, { useEffect, useState } from 'react'
import { HasFirebase, withFirebase } from './Firebase'
import { useParams } from 'react-router-dom'
import { UserProfile } from '../models'
import styles from './UserProfilePage.module.css'
import { Review } from '../models'
import ReviewList from './ReviewList'
import { timeSince } from '../formatUtils'

const UserProfilePage: React.FC<HasFirebase> = ({ firebase }) => {
    const { username } = useParams()
    const [ userProfile, setUserProfile ] = useState<UserProfile | null>(null)
    const [ reviews, setReviews ] = useState<Review[]>([])
    const [ isEditingBio, setIsEditingBio ] = useState(false)
    const [ isUpdatingBio, setIsUpdatingBio ] = useState(false)
    const [ bio, setBio ] = useState('')

    useEffect(() => {
        firebase.firestore
            .collection('users')
            .where('username', '==', username)
            .get()
            .then(res => {
                const doc = res.docs.pop()
                const profile = {
                    ...doc?.data() ,
                    uid: doc?.id,
                } as UserProfile
                setUserProfile(profile)
                setBio(profile.bio)
            })
    }, [ firebase.firestore, username ])


    useEffect(() => {
        const uid = userProfile?.uid
        if (!uid) return

        firebase.firestore
            .collection('reviews')
            .where('creatorId', '==', uid)
            .orderBy('createdAt', 'desc')
            .limit(12)
            .get()
            .then(res => 
                setReviews(res.docs.map(doc => doc.data() as Review)))

    }, [ firebase.firestore, userProfile ])

    const updateBio = () => {
        if (!userProfile) return 
        setIsUpdatingBio(true)

        firebase.firestore.collection('users').doc(userProfile.uid).update({
            bio
        })
        .then(res => firebase.firestore.collection('users').doc(userProfile.uid).get())
        .then(doc => {
            const profile = {
                ...doc?.data() ,
                uid: doc?.id,
            } as UserProfile
            setUserProfile(profile)
            setBio(profile.bio)
        })
        .then(() => setIsEditingBio(false))
        .finally(() => 
            setIsUpdatingBio(false))

    }

    return (<>
    <section>
        {userProfile && <div className={styles.userProfileCard}>
            <img src={userProfile.photoURL} alt={userProfile.displayName + "'s profile picture"}/>
            <div className={styles.userDetails}>
                <h2>{userProfile?.username}</h2>
                <a href={`https://github.com/${userProfile?.username}`}>github.com/{userProfile?.username}</a>
                <span>{userProfile?.commentCount} comment{userProfile.commentCount !== 1 && 's'}</span>
                <span> Joined {timeSince(userProfile.createdAt.toDate())} ago</span>
                <div className={styles.bio}>
                {isEditingBio 
                ? <form onSubmit={ev => {
                    ev.preventDefault()
                    updateBio()
                }}>
                    <textarea value={bio} onChange={ev => setBio(ev.target.value)}></textarea>
                    <button type="button" onClick={ev => {
                        ev.preventDefault()
                        setIsEditingBio(false)
                    }}>Cancel</button>
                    <button type="submit" disabled={isUpdatingBio}>Update</button>
                  </form>
                : ((userProfile.uid === firebase.currentUser()?.uid) 
                    ? <button onClick={() => setIsEditingBio(true)}>
                        <p>
                            {userProfile.bio || 'Click to add a bio'}
                        </p>
                      </button>
                    : <p>
                        {userProfile.bio || ''}
                      </p>)
                }</div>
            </div>
        </div>}
        {reviews.length > 0 && <h2>Reviews</h2>}
        <ReviewList reviews={reviews} />
    </section>
    </>)
}

export default withFirebase(UserProfilePage)