import React, { useState, useEffect } from 'react'
import { Notification } from '../models'
import { HasFirebase, withFirebase, useUserProfile, useUser } from './Firebase'
import styles from './NotificationsPage.module.css'
import { shortTimeSince } from '../formatUtils'
import { Link } from 'react-router-dom'

const NotificationsPage: React.FC<HasFirebase> = ({ firebase }) => {
    const [ notifications, setNotifications ] = useState<Notification[]>([])

    const uid = firebase.currentUser()?.uid
    const userProfile = useUserProfile()

    const setEmailNotifications = (v: boolean) => {
        firebase.firestore
            .collection('users')
            .doc(userProfile?.uid)
            .update({
                sendEmailNotifications: v,
            })
    }

    useEffect(() => {
        if (!uid) return

        firebase.firestore
            .collection('notifications')
            .where('receiver', '==', uid)
            .orderBy('createdAt', 'desc')
            .limit(40)
            .get()
            .then(res => {
                setNotifications(res.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                } as Notification)))
            })
    }, [ firebase, uid ])

    return <section>
        <h1>Notifications</h1>
        {userProfile?.sendEmailNotifications === false
        ? <div className={styles.emailInfo}>
            Email notifications disabled
            <button className={styles.emailToggle} onClick={() => setEmailNotifications(true)}>Enable</button>
          </div>
        : <div className={styles.emailInfo}>
            Email notifications enabled
            <button className={styles.emailToggle} onClick={() => setEmailNotifications(false)}>Disable</button>
          </div>
        }
        {notifications.map(n => 
            <div key={n.id}>
                <Link to={n.path} className={styles.notificationCard}>
                    <div className={styles.body}>
                        <div className={styles.notificationText}>{n.text}</div>
                        <div className={styles.createdAt}>{shortTimeSince(n.createdAt.toDate())} ago</div>
                    </div>
                </Link>
            </div>)}
    </section>
}

export default withFirebase(NotificationsPage)