import React, { useContext, useState, useEffect } from 'react'
import app, { auth } from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import { UserProfile } from '../models'

const config = {
    apiKey: "AIzaSyAxJBtnA_w8kUFwtEbLz0NyaXMwdsyDPjI",
    authDomain: "codereviewexchange.firebaseapp.com",
    databaseURL: "https://codereviewexchange.firebaseio.com",
    projectId: "codereviewexchange",
    storageBucket: "codereviewexchange.appspot.com",
    messagingSenderId: "145739588774",
    appId: "1:145739588774:web:7ec0f5d491959c03a00067"
}


export class Firebase {
    auth: app.auth.Auth
    firestore: app.firestore.Firestore

    constructor() {
        app.initializeApp(config)
        this.auth = app.auth()
        this.firestore = app.firestore()
    }

    signInWithGithub = () => {
        const provider = new auth.GithubAuthProvider()
        return this.auth.signInWithPopup(provider).then(res => {
            const user = res.user

            const username = res.additionalUserInfo?.username
            if (!user) {
                return
            }

            const userProfileDoc = this.firestore
                .collection('users')
                .doc(user.uid)

            return userProfileDoc.get()
                .then(doc => {
                    if (!doc.exists) {
                        return doc.ref.set({
                            username,
                            displayName: user.displayName,
                            photoURL: user.photoURL,
                            bio: "",
                            commentCount: 0,
                            reviewsGivenCount: 0,
                            sendEmailNotifications: true,
                            createdAt: app.firestore.FieldValue.serverTimestamp(),
                        })
                    }
                })
        })
    }

    currentUser = (): app.User | null => this.auth.currentUser
}

export const FirebaseContext = React.createContext<Firebase | null>(null)
export const useFirebase = () => useContext(FirebaseContext)
export const withFirebase = <P extends {}>(Component: React.FC<P & HasFirebase>) => (props: P) => (
    <FirebaseContext.Consumer>
      {firebase => 
        firebase 
        ? <Component {...props} firebase={firebase} /> 
        : <div>Waiting for firebase...</div>}
    </FirebaseContext.Consumer>)

export const useUser = () => {
    const firebase = useFirebase()

    const [ user, setUser ] = useState(firebase?.currentUser())

    useEffect(() => {
        const authStateListener = firebase?.auth.onAuthStateChanged(u => setUser(u))
        return authStateListener
    }, [ firebase ])

    return user
}

export const useUserProfile = () => {
    const firebase = useFirebase()
    const user = useUser()
    const [ userProfile, setUserProfile ] = useState<UserProfile | null>(null)

    useEffect(() => {
        if (!user || !firebase) {
            setUserProfile(null)
            return
        }

        const userProfileListener = 
            firebase.firestore
                .collection('users')
                .doc(user.uid)
                .onSnapshot(doc => 
                    setUserProfile({
                        ...doc.data(),
                        uid: user.uid,
                    }as UserProfile))

        return userProfileListener
    }, [ firebase, user ])

    return userProfile
}

export const useSingleDocumentQuery = <T extends {}>(query: app.firestore.Query<app.firestore.DocumentData>) => {
    const [ isLoading, setIsLoading ] = useState(true)
    const [ document, setDocument ] = useState<T | null>(null)

    useEffect(() => {
        query.get()
            .then(res => {
                setDocument(res.docs.pop()?.data() as T || null)
                setIsLoading(false)
            })
            .catch(err => console.log(err))
    })

    return [ document, isLoading ]
}

export const withUser = <P extends {}>(Component: React.FC<P & { user: app.User | null}>) => (props: P & HasFirebase) => {
    const [ user, setUser ] = useState(props.firebase.currentUser())
    
    useEffect(() => {
        const listener = props.firebase.auth.onAuthStateChanged(u => setUser(u))

        return listener
    }, [ props.firebase ])

    return <Component {...props} user={user}/>
}

export type HasFirebase = { firebase: Firebase }