export const timeSince = (date: Date) => {
    var seconds = Math.floor((Date.now() - date.valueOf()) / 1000);
    var intervalType;
  
    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = 'year';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'month';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'day';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }
  
    if (interval > 1 || interval === 0) {
      intervalType += 's';
    }
  
    return interval + ' ' + intervalType;
  };

export const shortTimeSince = (date: Date) => {
    var seconds = Math.floor((Date.now() - date.valueOf()) / 1000);
    var intervalType;
  
    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = 'y';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'm';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'd';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "h";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "min";
            } else {
              interval = seconds;
              intervalType = "s";
            }
          }
        }
      }
    }
  
    return interval + intervalType;
  };

  export const trimProtocol = (url: string): string => {
    if (url.startsWith('https://')) return url.slice('https://'.length)
    if (url.startsWith('http://')) return url.slice('http://'.length)
    return url
  }